const AUTH_START = "AUTH_START";
const AUTH_SUCCESS = "AUTH_SUCCESS";
const AUTH_FAIL = "AUTH_FAIL";
const LOGOUT = "LOGOUT";
const SET_READY = "SET_READY";
const UPDATE_USER = "UPDATE_USER";

const createAction = (type, payload) => ({
  type,
  payload
});

export const authStart = () => createAction(AUTH_START);
export const authFail = payload => createAction(AUTH_FAIL, payload);
export const authSuccess = payload => createAction(AUTH_SUCCESS, payload);
export const logout = payload => createAction(LOGOUT, payload);
export const setReady = () => createAction(SET_READY);
export const updateUser = () => createAction(UPDATE_USER);

export default function(state, { type, payload }) {
  const change = obj => ({ ...state, ...obj });
  switch (type) {
    case AUTH_START:
      return change({
        error: null,
        isAuthenticating: true
      });
    case AUTH_SUCCESS:
      return change({
        isAuthenticating: false,
        token: payload.token,
        isAuthenticated: true,
        user: formatUser(payload.user)
      });
    case AUTH_FAIL:
      return change({
        error: payload,
        isAuthenticating: false
      });
    case LOGOUT:
      localStorage.clear();
      return change({
        token: null,
        user: null,
        isAuthenticated: false,
        isAuthenticating: false
      });
    case SET_READY:
      return change({
        ready: true
      });
    case UPDATE_USER:
      return change({
        user: payload
      });
    default:
      throw new Error();
  }
}

function formatUser(user) {
  return {
    dateOfBirth: user.birth_date && new Date(user.birth_date),
    hasConsented: user.e_consentement,
    phoneNumber: user.phone_number || "",
    email: user.email || "",
    id: user.id
  };
}
