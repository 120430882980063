import { useContext } from "react";
import { authContext } from "Contexts/AuthProvider";

/**
 * Shows children (child component) only if
 * use is authenticated
 */
const UnAuthOnly = props => {
  const [{ isAuthenticated }] = useContext(authContext);

  return isAuthenticated ? null : props.children;
};

export default UnAuthOnly;
