import React, { useRef, useEffect } from "react";
import classes from "./LoadingPage.module.scss";

const isChrome = () =>
  /Chrome/.test(navigator.userAgent) &&
  /Google Inc/.test(window.navigator.vendor);

const LoadingPage = props => {
  const indicatorRef = useRef(null);

  useEffect(() => {
    if (!isChrome) {
      // ie fallback
      const pulse = indicatorRef.current;
      let offset = 46;
      let offsetPulse = () => {
        if (offset < 0) offset = 46;
        pulse.style.strokeDashoffset = offset;
        offset--;
        requestAnimationFrame(offsetPulse);
      };
      offsetPulse();
    }
  }, []);
  return (
    <div className={classes.root}>
      <div ref={indicatorRef} className={classes.indicator}>
        <svg width="16px" height="12px">
          <polyline
            className={classes.back}
            points="1 6 4 6 6 11 10 1 12 6 15 6"
          ></polyline>
          <polyline
            className={classes.front}
            points="1 6 4 6 6 11 10 1 12 6 15 6"
          ></polyline>
        </svg>
      </div>
    </div>
  );
};

export default LoadingPage;
