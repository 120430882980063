const SET_LOCALE = "SET_LOCALE";
const SET_ADD_TO_HOME = "SET_ADD_TO_HOME";

const createAction = (type, payload) => ({
  type,
  payload
});

export const setShowAddToHome = p => createAction(SET_ADD_TO_HOME, p);
export const setLocale = p => createAction(SET_LOCALE, p);

export default function(state, { type, payload }) {
  const change = obj => ({ ...state, ...obj });
  switch (type) {
    case SET_LOCALE:
      return change({
        locale: payload
      });
    case SET_ADD_TO_HOME:
      return change({
        showAddToHome: payload
      });
    default:
      throw new Error();
  }
}
