import { useContext } from "react";
import { authContext } from "Contexts/AuthProvider";

/**
 * Shows children (child component) only if
 * use is authenticated
 */
const AuthOnly = props => {
  const [{ isAuthenticated }] = useContext(authContext);

  return isAuthenticated ? props.children : null;
};

export default AuthOnly;
