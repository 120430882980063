import React, { useReducer } from "react";
import reducer from "./reducer";
import { IntlProvider } from "react-intl";
import translations from "i18n/locales";

export const appContext = React.createContext();

const initialState = {
  locale: "fr",
  showAddToHome: false
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { locale } = state;
  const messages = translations[locale];

  return (
    <appContext.Provider value={[state, dispatch]}>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        {children}
      </IntlProvider>
    </appContext.Provider>
  );
};

export default AppProvider;
