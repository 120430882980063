import React, { useReducer, useEffect } from "react";
import reducer, { authStart, authSuccess, logout } from "./reducer";
import { fetchUserInfo } from "api/user";
import LoadingPage from "Containers/LoadingPage/LoadingPage";
import { setReady } from "./reducer";

export const authContext = React.createContext();

const initialState = {
  token: null,
  isAuthenticating: false,
  isAuthenticated: false,
  user: null,
  error: null,
  ready: false
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    tryAutoSignIn();

    async function tryAutoSignIn() {
      dispatch(authStart());
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const user = await fetchUserInfo();
          dispatch(authSuccess({ user, token }));
        } catch (err) {
          dispatch(logout());
        }
      } else {
        dispatch(logout());
      }
      dispatch(setReady());
    }
  }, []);

  return (
    <authContext.Provider value={[state, dispatch]}>
      {state.ready ? children : <LoadingPage />}
    </authContext.Provider>
  );
};

export default AuthProvider;
