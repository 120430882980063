import React from "react";
import AuthProvider from "Contexts/AuthProvider/AuthProvider";
import ErrorBoundary from "Components/ErrorBoundary/ErrorBoundary";
import Routes from "./Routes";
import AppProvider from "Contexts/AppProvider";
import LangSetter from "Components/LangSetter/LangSetter";
import AddToHome from "Components/AddToHome";
import { isMobile } from "Utils";

const AddToHomeMobile = () => (isMobile ? <AddToHome /> : null);

const App = () => (
  <ErrorBoundary>
    <AuthProvider>
      <AppProvider>
        <LangSetter />
        <AddToHomeMobile />
        <Routes />
      </AppProvider>
    </AuthProvider>
  </ErrorBoundary>
);

export default App;
