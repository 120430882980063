import React from "react";
import Toolbar from "Components/Navigation/Toolbar/Toolbar";
import Footer from "Components/Navigation/Footer/Footer";
import classes from "./Layout.module.scss";

const Layout = props => {
  return (
    <React.Fragment>
      <Toolbar />
      <main as="main" className={classes.main}>
        {props.children}
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
