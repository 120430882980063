import React from "react";
import { Navbar, Dropdown, Nav } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import classes from "./Toolbar.module.scss";
import { ReactComponent as HamburgerSVG } from "assets/svg/hamburger.svg";
import { ReactComponent as TropheSVG } from "assets/svg/trophee.svg";
import { ReactComponent as HomeSVG } from "assets/svg/home.svg";
import { ReactComponent as VideoSVG } from "assets/svg/video.svg";
import { ReactComponent as LogoutSVG } from "assets/svg/logout.svg";
import { ReactComponent as LoginSVG } from "assets/svg/login.svg";
import { ReactComponent as TbibcomSVG } from "assets/svg/tbibcom.svg";
import Hidden from "Components/Utils/Hidden/Hidden";
import AuthOnly from "Components/Auth/AuthOnly";
import UnAuthOnly from "Components/Auth/UnAuthOnly";
import { withRouter } from "react-router-dom";
import LocalesDropdown from "Components/LocalesDropdown/LocalesDropdown";

/**
 * i18n imports
 */
import { injectIntl, defineMessages } from "react-intl";

const intlMessages = defineMessages({
  homeLink: {
    id: "Toolbar.homeLink",
    defaultMessage: "Home"
  },
  videosLink: {
    id: "Toolbar.videosLink",
    defaultMessage: "Videos"
  },
  profileLink: {
    id: "Toolbar.profileLink",
    defaultMessage: "Profile"
  },
  logoutLink: {
    id: "Toolbar.logoutLink",
    defaultMessage: "Logout"
  },
  loginLink: {
    id: "Toolbar.loginLink",
    defaultMessage: "Login"
  }
});

const Hamburger = React.forwardRef((props, ref) => (
  <button {...props}>
    <HamburgerSVG />
  </button>
));

const NAV_LINKS = [
  {
    key: "homeLink",
    path: "/",
    ICON: HomeSVG,
    name: "home"
  },
  {
    key: "videosLink",
    path: "/videos",
    ICON: VideoSVG,
    name: "videos"
  },
  {
    key: "profileLink",
    path: "/profile",
    ICON: TropheSVG,
    name: "profile"
  },
  {
    key: "logoutLink",
    path: "/logout",
    ICON: LogoutSVG,
    name: "logout"
  }
];

const Toolbar = props => {
  const {
    intl: { formatMessage }
  } = props;
  return (
    <Navbar
      dir="ltr"
      as="header"
      // eslint-disable-next-line jsx-a11y/aria-role
      role={null}
      className={classes.root}
      expand="lg"
    >
      <IndexLinkContainer to="/">
        <Navbar.Brand className={classes.brand}>
          <TbibcomSVG />
        </Navbar.Brand>
      </IndexLinkContainer>

      <Nav as="nav" role="navigation" className={classes.innerWrapper}>
        <LocalesDropdown />
        <UnAuthOnly>
          <ul className={classes.desktopMenu}>
            <li className={classes.menuItem}>
              <IndexLinkContainer to="/login">
                <Nav.Link>
                  <LoginSVG />
                  <span className="sr-only">
                    {formatMessage(intlMessages.loginLink)}
                  </span>
                </Nav.Link>
              </IndexLinkContainer>
            </li>
          </ul>
        </UnAuthOnly>
        {/* Desktop navigation */}
        <Hidden down="md">
          <ul className={classes.desktopMenu}>
            <AuthOnly>
              {NAV_LINKS.map(link => (
                <li key={link.key} className={classes.menuItem}>
                  <IndexLinkContainer to={link.path} exact>
                    <Nav.Link data-link-name={link.name}>
                      <link.ICON />
                      <span className="sr-only">
                        {formatMessage(intlMessages[link.key])}
                      </span>
                    </Nav.Link>
                  </IndexLinkContainer>
                </li>
              ))}
            </AuthOnly>
          </ul>
        </Hidden>
        {/* Mobile navigation */}
          <Hidden up="md">
            <Dropdown>
              <AuthOnly>
                <Dropdown.Toggle
                  className={classes.dropdownToggle}
                  as={Hamburger}
                  aria-controls="collpased-navbar-nav">
                  <HamburgerSVG />
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul" className={classes.dropdownMenu} alignRight>
                    {NAV_LINKS.map(link => (
                      <Dropdown.Item key={link.key} as="li">
                        <IndexLinkContainer to={link.path} exact>
                          <Nav.Link>
                            <link.ICON />
                          </Nav.Link>
                        </IndexLinkContainer>
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </AuthOnly>
            </Dropdown>
          </Hidden>
      </Nav>
    </Navbar>
  );
};

/**
 * @todo Add focus highlighting to nav items
 */

export default injectIntl(withRouter(Toolbar));
