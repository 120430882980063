import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { breakpoints } from "styles";

/**
 * Renders child according to breakpoint passed
 * If you pass up="md" it will not render child components
 * for viewport >= "md"
 * If you pass down="md" it will not render child components
 * for viewport < "md"
 */
const Hidden = ({ up, down, ...props }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    /**
     * Note: This is a big performance hit,
     * refactor late.
     */
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  function updateWidth() {
    setWidth(window.innerWidth);
  }

  const hide = up
    ? width <= breakpoints[up]
    : down
    ? width > breakpoints[down]
    : false;

  return hide ? props.children : null;
};

Hidden.proptypes = {
  up: PropTypes.oneOf(Object.keys(breakpoints)),
  down: PropTypes.oneOf(Object.keys(breakpoints))
};

export default Hidden;
