import React from "react";
import classes from "./Footer.module.scss";
import { Container } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";

const intlMessages = defineMessages({
  legalMentions: {
    id: "Footer.legalMentions",
    defaultMessage: "Conditions générales"
  },
  // faq: {
  //   id: "Footer.faq",
  //   defaultMessage: "FAQ"
  // },
  contact: {
    id: "Footer.contact",
    defaultMessage: "Contact"
  },
  rights: {
    id: "Footer.rights",
    defaultMessage: "Tous droits réservés"
  }
});

const Footer = props => {
  const {
    intl: { formatMessage }
  } = props;

  return (
    <footer className={classes.root}>
      <div className={classes.background} />
      <Container className="d-flex justify-content-between" fluid>
        <ul className="mb-3 mr-n3">
          <li className="m-0 mr-3 d-inline-block">
            <Link to="/pages/terms">
              {formatMessage(intlMessages.legalMentions)}
            </Link>
          </li>
          {/* <li className="m-0 mr-3 d-inline-block">
            <Link to="/pages/faq">{formatMessage(intlMessages.faq)}</Link>
          </li> */}
          <li className="m-0 mr-3 d-inline-block">
            <Link to="/pages/contact">
              {formatMessage(intlMessages.contact)}
            </Link>
          </li>
        </ul>
        <p className="text-center m-0">{formatMessage(intlMessages.rights)}</p>
      </Container>
    </footer>
  );
};

export default injectIntl(Footer);
