import request from "Utils/request";

export const fetchUserInfo = params => {
  return request({
    url: "/users/current",
    method: "GET"
  });
};

export const updateUserInfo = params => {
  return request({
    url: "/users/current",
    method: "PUT",
    params: { user: { ...params } }
  });
};
