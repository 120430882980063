import React from "react";
import classes from "./LocalesDropdown.module.scss";
import { Dropdown } from "react-bootstrap";
import { appContext } from "Contexts/AppProvider";
import { clsx } from "Utils";
import qs from "qs";
import { useLocation, useHistory } from "react-router-dom";

/**
 * i18n imports
 */
import { injectIntl, defineMessages } from "react-intl";

const intlMessages = defineMessages({
  ar: {
    id: "Toolbar.arLocale",
    defaultMessage: "Arabic"
  },
  fr: {
    id: "Toolbar.frLocale",
    defaultMessage: "French"
  },
  en: {
    id: "Toolbar.enLocale",
    defaultMessage: "English"
  }
});

const LOCALES = [
  {
    key: "ar",
    label: "Arabic",
    icon: require("assets/arabic.png")
  },
  {
    key: "fr",
    label: "French",
    icon: require("assets/french.png")
  },
  {
    key: "en",
    label: "English",
    icon: require("assets/english.png")
  }
];

const DropdownToggle = React.forwardRef(({ label, icon, ...props }, ref) => (
  <button {...props}>
    <img src={icon} alt={`${label} language`} />
  </button>
));

const LocalesDropdown = props => {
  const location = useLocation();
  const history = useHistory();
  const [{ locale }] = React.useContext(appContext);

  const {
    intl: { formatMessage }
  } = props;

  function setLocaleSearchParam(newLocale) {
    const searchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });
    searchParams.locale = newLocale;
    const search = qs.stringify(searchParams, { addQueryPrefix: true });
    history.push({ ...location, search });
  }

  const current = LOCALES.find(item => item.key === locale);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={clsx(classes.toggle)}
        as={DropdownToggle}
        id="locales-dropdown"
        {...current}
      />

      <Dropdown.Menu className={classes.menu} alignRight>
        {LOCALES.map((item, i) => (
          <Dropdown.Item
            as="button"
            key={item.key}
            active={item.key === locale}
            className={classes.menuItem}
            eventKey={item.key}
            onClick={() => setLocaleSearchParam(item.key)}
          >
            <img
              height={38}
              width={48}
              src={item.icon}
              alt={`${item.label} language`}
            />
            <span className="sr-only">
              {formatMessage(intlMessages[item.key])}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default injectIntl(LocalesDropdown);
