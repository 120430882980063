import React, { useContext, useEffect, useRef } from "react";
import classes from "./AddToHome.module.scss";
import { Toast, Button } from "react-bootstrap";
import { appContext, setShowAddToHome } from "Contexts/AppProvider";
/**
 * i18n imports
 */
import { useIntl, defineMessages } from "react-intl";

const intlMessages = defineMessages({
  heading: {
    id: "AddToHome.heading",
    defaultMessage: "Add home screen shortcut"
  },
  confirm: {
    id: "AddToHome.confirm",
    defaultMessage: "Add"
  },
  reject: {
    id: "AddToHome.reject",
    defaultMessage: "Cancel"
  }
});

const AddToHome = () => {
  const { formatMessage } = useIntl();
  const [{ showAddToHome }, dispatch] = useContext(appContext);
  const closeTooltip = () => dispatch(setShowAddToHome(false));
  const ref = useRef(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", event => {
      event.preventDefault();
      // Stash the event so it can be triggered later.
      ref.current = event;
      dispatch(setShowAddToHome(true));
    });
  }, [dispatch]);

  const handleAddClick = () => {
    closeTooltip();
    // Show the prompt
    ref.current.prompt();
    // Wait for the user to respond to the prompt
    ref.current.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      ref.current = null;
    });
  };

  return (
    <Toast show={showAddToHome} className={classes.root} onClose={closeTooltip}>
      <Toast.Header className={classes.header}>
        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
        <strong className="mr-auto">
          {formatMessage(intlMessages.heading)}
        </strong>
      </Toast.Header>
      <Toast.Body className="d-flex">
        <Button
          variant="primary"
          size="sm"
          className="ml-auto"
          onClick={handleAddClick}
        >
          {formatMessage(intlMessages.confirm)}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          className="ml-3"
          onClick={closeTooltip}
        >
          {formatMessage(intlMessages.reject)}
        </Button>
      </Toast.Body>
    </Toast>
  );
};

export default AddToHome;
