import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import qs from "qs";
import PropTypes from "prop-types";
import { authContext } from "Contexts/AuthProvider";

/**
 * List of auth routes to whitelist if user hasn't
 * given consent yet
 */
const consentWhiteList = ["/logout", "/user-consent"];
const AuthenticatedRoute = ({ component: C, isAuthenticated, ...rest }) => {
  const [{ user }] = useContext(authContext);
  const noConsent = user && !user.hasConsented;
  return (
    <Route
      {...rest}
      render={props => {
        const searchQuery = {
          redirect: props.location.pathname,
          ...qs.parse(props.location.search, { ignoreQueryPrefix: true })
        };
        return isAuthenticated ? (
          noConsent &&
          consentWhiteList.indexOf(props.location.pathname) === -1 ? (
            <Redirect
              to={{
                pathname: "/user-consent",
                state: { from: props.location }
              }}
            />
          ) : (
            <C {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: qs.stringify(searchQuery, { addQueryPrefix: true }),
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

AuthenticatedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
};

export default AuthenticatedRoute;
