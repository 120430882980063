import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import UnauthenticatedRoute from "Components/Auth/UnauthenticatedRoute";
import AuthenticatedRoute from "Components/Auth/AuthenticatedRoute";
import { authContext } from "Contexts/AuthProvider";
import Layout from "Containers/Layout/Layout";
import LoadingPage from "Containers/LoadingPage/LoadingPage";

// lazy loaded routes
const Login = React.lazy(() =>
  import(/* webpackChunkName: "Login"*/ "Containers/Login/Login")
);
const Home = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ "Containers/Home/Home")
);
const Videos = React.lazy(() =>
  import(/* webpackChunkName: "Video" */ "Containers/Videos/Videos")
);
const VideoDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "VideoDetails" */ "Containers/VideoDetails/VideoDetails"
  )
);
const Profile = React.lazy(() =>
  import(/* webpackChunkName: "GamePage" */ "Containers/Profile/Profile")
);
const Logout = React.lazy(() =>
  import(/* webpackChunkName: "Logout" */ "Containers/Logout/Logout")
);

// Legal Pages
const UserConsentPage = React.lazy(() =>
  import(
    /* webpackChunkName: "UserConsentPage" */ "Containers/UserConsentPage/UserConsentPage"
  )
);
const StaticPages = React.lazy(() =>
  import(
    /* webpackChunkName: "StaticPages" */ "Containers/StaticPages/StaticPages"
  )
);
const NotFound = React.lazy(() =>
  import(/* webpackChunkName: "NotFound" */ "Containers/NotFound/NotFound")
);

const publicRoutes = [
  {
    Component: Home,
    path: "/",
    isExact: true
  },
  {
    Component: StaticPages,
    path: "/pages"
  }
];

const authRoutes = [
  {
    Component: Videos,
    path: "/videos",
    isExact: true
  },
  {
    Component: VideoDetails,
    path: "/videos/:id",
    isExact: true
  },
  {
    Component: Profile,
    path: "/profile",
    isExact: true
  },
  {
    Component: Logout,
    path: "/logout",
    isExact: true
  }
];

/**
 * @todo stop routing to login on logout, route to "/" instead
 */
export default () => {
  const [{ isAuthenticated }] = React.useContext(authContext);

  return (
    <Layout>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {publicRoutes.map(route => (
            <Route key={route.path} path={route.path} exact={route.isExact}>
              <route.Component />
            </Route>
          ))}

          <UnauthenticatedRoute
            isAuthenticated={isAuthenticated}
            path="/login"
            exact
            component={() => <Login />}
          />

          <AuthenticatedRoute
            isAuthenticated={isAuthenticated}
            component={() => <UserConsentPage />}
            path="/user-consent"
            exact={true}
          />

          {authRoutes.map(route => (
            <AuthenticatedRoute
              key={route.path}
              isAuthenticated={isAuthenticated}
              path={route.path}
              exact={route.isExact}
              component={route.Component}
            />
          ))}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
};
